.menuItem {
  color: red;
}
.middlebody {
  background-image: url("./images/djpic1-MB.jpeg") !important;
  background-repeat: no-repeat !important;
  background-attachment: fixed !important;
  background-position: left !important;
  background-size: 319px 603px !important;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.carousel {
  overflow-y: scroll;
}

/* .App {
  text-align: center;
}



.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
